import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, Grid, InputAdornment,
    InputLabel, MenuItem, Select, Switch
} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";

export default function WebsiteUpsert (params: {website: any, setWebsite: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [code, setCode] = React.useState('' as string);
    const [url, setUrl] = React.useState('');

    React.useEffect(() => {
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (params.website) {
            setCode(params.website.code || '');
            setUrl(params.website.url || '');
        } else {
            setCode('');
            setUrl('');
        }
    }, [params.website]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setWebsite(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $website: WebsiteWrite!) {
                    websiteUpdate(id: $id, website: $website) {
                        id
                    }
                }
            `;

            variables = {
                id: params.website.id,
                website: {
                    code,
                    url
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.websiteUpdate;
        } else {
            q = gql`
                mutation m($website: WebsiteWrite!) {
                    websiteCreate(website: $website) {
                        id
                    }
                }
            `;

            variables = {
                website: {
                    code,
                    url
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.websiteCreate && res.websiteCreate.id;
        }

        if (good) {
            toast.success(isEdit ? 'Website updated successfully' : 'Website created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.website) return <> </>;

    const isEdit = !!params.website.id;

    return (
        <>
                <Dialog
                    open={!!params.website}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {isEdit ? `Update Website ${params.website.name}` : 'Create Website'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="code"
                                    label="Code"
                                    type="text"
                                    fullWidth
                                    size={'small'}
                                    value={code}
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    margin="dense"
                                    id="url"
                                    label="Url"
                                    type="text"
                                    fullWidth
                                    size={'small'}
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
