import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import {getAssignedPublishers, searchCf} from "./usersService";
import MultiSelect from "../../components/multiSelect/multiSelect";

export default function SelfServe (params: {user: any, setUser: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [selectedCampaigns, setSelectedCampaigns] = React.useState([] as any[]);

    React.useEffect(() => {
        if (params.user) {
            if (params.user.id) {
                searchCf("", [], params.user.id).then(x=> {
                    setSelectedCampaigns(x);
                });

            }
        }
        setLoading(false);
    }, [params.user]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setUser(null);
        setSelectedCampaigns([]);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        q = gql`
            mutation m($id: Int!, $cfIds: [Int!]!) {
                userAssignSelfServe(id: $id, cfIds: $cfIds)
            }
        `;

        variables = {
            id: params.user.id,
            cfIds: selectedCampaigns.map((pid:any) => { return pid.id})
        };

        setLoading(true);

        const res = await mutate(q, variables);
        good = res && res.userAssignSelfServe;

        if (good) {
            toast.success('Assigned Self Serve Successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }
    }

    if (!params.user) return <> </>;

    return (
        <>
                <Dialog
                    open={!!params.user}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'md'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Assign Self Serve to ${params.user.name}`}
                    </DialogTitle>
                    <DialogContent>
                        <MultiSelect
                            searchFunction={searchCf}
                            selectedItems={selectedCampaigns}
                            setSelectedItems={setSelectedCampaigns}
                            placeholder={"Select CF Campaigns"}
                        />
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>Save</Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
