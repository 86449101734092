import * as React from "react";
import DateRange, {RangeToTimes} from "../components/DateRange";
import Loading from "../components/loading";
import {gql} from "@apollo/client";
import {query} from "../lib/apollo";
import {
    Alert, FormControl, IconButton, Input, InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow, TableSortLabel
} from "@mui/material";
import theme from "../lib/theme";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import StripedTableRow from "../components/StripedTableRow";
import TableFooterRow from "../components/TableFooterRow";
import TableHeaderRow from "../components/TableHeaderRow";
import Button from "@mui/material/Button";
import theme2 from "../lib/theme";
import CachedIcon from "@mui/icons-material/Cached";
import MultiSelect from "../components/multiSelect/multiSelect";

const AVAILABLE_COLUMNS = [
    {id: 'result', value: 'Result'},
    {id: 'publisher', value: 'Publisher'},
    {id: 'agent', value: 'Agent'},
    {id: 'wall', value: 'Wall'},
    {id: 'degree_seeking', value: 'Degree Seeking'},
];

export default function ClickWallDialerStatsPage() {

    React.useEffect(() => {
        document.title = "Dialer Stats | DN";
    }, []);


    const [dateRange, setDateRange] = React.useState([] as any);
    const [loading, setLoading] = React.useState(true);
    const [results, setResults] = React.useState([] as any);
    const [columns, setColumns] = React.useState([] as any);
    const [selectedData, setSelectedData] = React.useState([{id: 'publisher', value: 'Publisher'}] as any);
    const [search, setSearch] = React.useState<string>('');
    const [filtered, setFiltered] = React.useState([] as any);

    React.useEffect(() => {
        let d = RangeToTimes("Today");;
        setDateRange(d);
        reload(d).then((() => {}));
    }, []);

    const updateFiltered = (s: string, r: any) => {
        let f = JSON.parse(JSON.stringify(r));
        if (f.length > 0 && s) {
            s = s.trim().toLowerCase();
            f = f.filter((row: any) => {
                let found = false;
                row.columns.forEach((column: any) => {
                    if (column.value.toLocaleString().toLowerCase().includes(s)) {
                        found = true;
                    }
                });
                return found;
            });

        }

        setFiltered(f);
    }

    const reload = async (date:  any) =>{
        if (selectedData && selectedData.length > 0) {
            setLoading(true);
            let c = selectedData.map((x: any) => ({key: x.id, label: x.value}));
            if (c.find((x: any) => x.key === 'publisher')) {
                c.push({key: 'publisher_id', label: 'Publisher ID'});
            }
            setColumns(c);
            const q = gql`
                query q($searchCriteria: ClickWallDialerSearchCriteria!) {
                    clickWallDialerStats(searchCriteria: $searchCriteria) {
                        columns {
                            dataType
                            value
                        }
                        total
                        badData
                        goodData
                    }
                }
            `;
            const variables = {
                searchCriteria: {
                    startDate: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                    endDate: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
                    "dataTypes": c.map((x: any) => x.key),
                }
            }

            const response = await query(q, variables);

            if (response && response.clickWallDialerStats) {
                setResults(response.clickWallDialerStats);
                updateFiltered(search, response.clickWallDialerStats);
            }

            setLoading(false);
        }
    }

    async function getData(search: string, selected: any[]) {
        let s = selected.map((x: any) => x.id);
        return AVAILABLE_COLUMNS.filter((x: any) => x.value.toLowerCase().includes(search.toLowerCase()) && !s.includes(x.id));
    }

    return (
        <>
            <h2>Dialer Stats</h2>

            {loading ? <Loading/> :
                <>
                    <Box sx={{display: 'flex', flexDirection: 'row', }}>
                        <DateRange showTime={false} dateRange={dateRange} setDateRange={(value: any) => setDateRange(value)} style={{marginRight: '10px'}}/>
                        <FormControl variant="standard"
                                     sx={{marginRight: "10px", width: "100px"}}>
                        <MultiSelect searchFunction={getData} selectedItems={selectedData} setSelectedItems={setSelectedData} placeholder={"Data"}/>
                        </FormControl>
                        <Button aria-label="refresh" onClick={() => reload(dateRange)} variant={'contained'} color={'success'}>
                            Run
                        </Button>
                        {results.length > 0 ? <Input
                            id="search"
                            type="text"
                            spellCheck="false"
                            placeholder="Search"
                            value={search || ''}
                            onChange={(e: any) => {setSearch(e.target.value); updateFiltered(e.target.value, results);} }
                            startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                            sx={{maxWidth: '200px', marginLeft: '10px'}}
                        /> : <> </>}
                    </Box>

                    <p></p>
                    {results.length === 0 ? <Alert severity="warning">No results found in date range</Alert> :
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                        <Table>
                            <TableHead>
                                <TableHeaderRow>
                                    {columns.filter((x: any) => x.key !== 'publisher_id').map((column: any, i: number) => (
                                        <TableCell key={i}>
                                            <TableSortLabel>{column.label}</TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell align="right">Total</TableCell>
                                    <TableCell align="right">Bad Data</TableCell>
                                    <TableCell align="right">Good Data</TableCell>
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                                {filtered.map((row: any, i: number) => (
                                <StripedTableRow key={i}>
                                    {columns.filter((x: any) => x.key !== 'publisher_id').map((column: any, j: number) => (
                                        <TableCell key={j}><PublisherValue row={row} columnKey={column.key}/></TableCell>
                                    ))}
                                    <TableCell align="right">{row.total.toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                    <TableCell align="right" sx={{backgroundColor: getBadColor(row.total, row.badData / row.total * 100)}}>{row.badData.toLocaleString(undefined, {maximumFractionDigits:0})} ({(row.badData / row.total * 100).toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                    <TableCell align="right" sx={{backgroundColor: getGoodColor(row.total, row.goodData / row.total * 100)}}>{row.goodData.toLocaleString(undefined, {maximumFractionDigits:0})} ({(row.goodData / row.total * 100).toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                </StripedTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableFooterRow>
                                    <TableCell colSpan={columns.filter((x: any) => x.key !== 'publisher_id').length}>Totals:</TableCell>
                                    <TableCell align="right">{filtered.reduce((a: any, b: any) => a + b.total, 0).toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                    <TableCell align="right">{filtered.reduce((a: any, b: any) => a + b.badData, 0).toLocaleString(undefined, {maximumFractionDigits:0})} ({(filtered.reduce((a: any, b: any) => a + b.badData, 0) / filtered.reduce((a: any, b: any) => a + b.total, 0) * 100).toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                    <TableCell align="right">{filtered.reduce((a: any, b: any) => a + b.goodData, 0).toLocaleString(undefined, {maximumFractionDigits:0})} ({(filtered.reduce((a: any, b: any) => a + b.goodData, 0) / filtered.reduce((a: any, b: any) => a + b.total, 0) * 100).toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                </TableFooterRow>
                            </TableFooter>
                        </Table>
                        </TableContainer>
                    }
            </>}
        </>
    )
}

function getBadColor(total:number, percent: number) {
    if (percent > 10 && total >= 50) {
        return theme2.palette.error.light
    }
    return undefined;
}

function getGoodColor(total: number, percent: number) {
    if (percent < 0.5 && total >= 50) {
        return theme2.palette.error.light
    } else if (percent >= 6.5) {
        return theme2.palette.success.light
    }
    return undefined;
}

function PublisherValue(props: {row: any, columnKey: string}) {
    if (props.columnKey === 'publisher') {
        const publisherId = props.row.columns.find((x: any)=>x.dataType === 'publisher_id').value;
        return (
            <a href={`/publishers/${publisherId}`} target={"_blank"}>
                {props.row.columns.find((x: any)=>x.dataType === props.columnKey).value + ` (${publisherId})`}
            </a>
        )
    }
    return   (
        <>
        {props.row.columns.find((x: any)=>x.dataType === props.columnKey).value}
        </>
    )
}

