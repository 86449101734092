import * as React from "react";
import { downloadUsers, searchUsers } from "./usersService";
import Loading from "../../components/loading";
import {
    Alert, FormControl,
    FormGroup, IconButton, Input, InputAdornment, Menu, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableSortLabel,
    Button,
    FormControlLabel,
    Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../lib/UseDebounce";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import LoginIcon from '@mui/icons-material/Login';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import UserUpsert from "./userUpsert";
import UserToggleActive from "./userToggleActive";
import UserLoginAs from "./userLoginAs";
import UserAssignPubs from "./userAssignPubs";
import UserAssignCampaigns from "./userAssignCampaigns";
import {useAuth} from "../../providers/auth";
import {TouchApp} from "@mui/icons-material";
import SelfServe from "./selfServe";

export default function UsersPage() {
    let auth = useAuth();
    const [users, setUsers] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState('');
    const [active, setActive] = React.useState(true);
    const [userActive, setUserActive] = React.useState<any>(null as any);
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const [userUpsert, setUserUpsert] = React.useState<any>(null as any);
    const [userLoginAs, setUserLoginAs] = React.useState<any>(null as any);
    const [userAssignPubs, setUserAssignPubs] = React.useState<any>(null as any);
    const [userAssignCampaigns, setUserAssignCampaigns] = React.useState<any>(null as any);
    const [selfServe, setSelfServe] = React.useState<any>(null as any);
    const [count, setCount] = React.useState(0);
    const [sort, setSort] = React.useState({column: 'id', sortAsc: true});

    //
    React.useEffect(() => {
        document.title = "Users | DN";
    }, []);

    //
    const reload = async (isActive: boolean, sSearch: string, sort: any) => {
        setLoading(true);
        const p = await searchUsers(isActive, sSearch, sort.column, sort.sortAsc);
        setUsers(p.users);
        setCount(p.count);
        setLoading(false);
    }

    React.useEffect(() => {
        reload(active, search, sort).then(x=>{});
    }, []);


    const handleActive = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setActive(event.target.checked);
        await reload(event.target.checked, search, sort);
    };

    useDebounce(() => {
        reload(active, search, sort).then(x=>{});
        }, [search], 400
    );

    const doSort = async (column: string, defaultAsc: boolean = false) => {
        let s;
        if (sort.column === column) {
            s = {column: column, sortAsc: !sort.sortAsc};
        } else {
            s = {column: column, sortAsc: defaultAsc};
        }

        setSort(s);
        await reload(active, search, s);
    }

    //
    return (
        <>
            <h2>Users</h2>
            <FormGroup row={true}>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => setSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>
                {!!users && !!users.length && !loading && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="download" onClick={() => { downloadUsers(search, active, sort.column, sort.sortAsc) }} variant={'contained'} color={'success'}>
                        <FileDownloadIcon />&nbsp;Export
                    </Button>
                </FormControl>}

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="Add" onClick={() => { setUserUpsert({}) }} variant={'contained'} color={'warning'}>
                        <AddBoxOutlinedIcon />&nbsp;Create User
                    </Button>
                </FormControl>

                <FormControlLabel control={<Switch checked={active} onChange={handleActive} />} label="Active" />
            </FormGroup>
            {loading ? <Loading/> :
                <>
                    {!users || users.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'id'}
                                                direction={sort.column === 'id' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('id', false)}}
                                            >
                                                Id
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'name'}
                                                direction={sort.column === 'name' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('name', true)}}
                                            >
                                                Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'email'}
                                                direction={sort.column === 'email' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('email', false)}}
                                            >
                                                Email
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'role'}
                                                direction={sort.column === 'role' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('role', false)}}
                                            >
                                                Role
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((row: any) => (
                                        <React.Fragment key={row.id}>
                                            <StripedTableRow>
                                            <TableCell>
                                                {row.id}
                                            </TableCell>
                                            <TableCell>
                                                {row.name}
                                            </TableCell>
                                            <TableCell>
                                                {row.email}
                                            </TableCell>
                                            <TableCell>
                                                {row.role.value}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                    }}
                                                >
                                                    <MoreVertIcon sx={{fontSize: '15px'}} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEls[row.id]}
                                                    open={!!anchorEls[row.id]}
                                                    onClose={() => {
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }}
                                                >
                                                    <MenuItem onClick={() => {
                                                        setUserUpsert(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit User</MenuItem>

                                                    {!row.active && auth.user?.id != row.id && <MenuItem onClick={() => {
                                                        setUserActive(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><DoneOutlineIcon  sx={{marginRight: 1}}/> Activate</MenuItem>}

                                                    {row.active && auth.user?.id != row.id && <MenuItem onClick={() => {
                                                        setUserActive(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><HighlightOffIcon  sx={{marginRight: 1}}/> De-Activate</MenuItem>}

                                                    {row.active && auth.user?.id != row.id && <MenuItem onClick={() => {
                                                        setUserLoginAs(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><LoginIcon  sx={{marginRight: 1}}/> Login As</MenuItem>}

                                                    {(row.role.id == 8 || row.role.id == 10) && <MenuItem onClick={() => {
                                                        setUserAssignPubs(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><AssignmentOutlinedIcon  sx={{marginRight: 1}}/> Assign Pubs</MenuItem>}

                                                    {row.role.id == 6 && <MenuItem onClick={() => {
                                                        setUserAssignCampaigns(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><AssignmentOutlinedIcon  sx={{marginRight: 1}}/> Assign Campaigns</MenuItem>}

                                                    {row.role.id == 14 && <MenuItem onClick={() => {
                                                        setSelfServe(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><TouchApp  sx={{marginRight: 1}}/> Assign Self Serve</MenuItem>}
                                                </Menu>
                                            </TableCell>
                                        </StripedTableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>
                    }
                </>
            }
            <UserToggleActive user={userActive} setUser={setUserActive} reload={() => {reload(active, search, sort).then(() => {})}} />
            <UserUpsert user={userUpsert} setUser={setUserUpsert} reload={() => {reload(active, search, sort).then(() => {})}} />
            <UserAssignCampaigns user={userAssignCampaigns} setUser={setUserAssignCampaigns} reload={() => {reload(active, search, sort).then(() => {})}} />
            <UserAssignPubs user={userAssignPubs} setUser={setUserAssignPubs} reload={() => {reload(active, search, sort).then(() => {})}} />
            <SelfServe user={selfServe} setUser={setSelfServe} reload={() => {reload(active, search, sort).then(() => {})}} />
            <UserLoginAs user={userLoginAs} setUser={setUserLoginAs} auth={auth} />
        </>
    )
}
