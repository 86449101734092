import * as React from "react";
import Loading from "../../components/loading";
import {
    Alert, FormControl,
    FormControlLabel, FormGroup, IconButton, Menu, MenuItem,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Button,
} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StripedTableRow from "../../components/StripedTableRow";
import TableHeaderRow from "../../components/TableHeaderRow";
import { query } from "../../lib/apollo";
import {gql} from "@apollo/client";
import WebsiteUpsert from "./websiteUpsert";

export default function WebsitesPage() {
    //
    const [allWebsites, setAllWebsites] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [websiteUpsert, setWebsiteUpsert] = React.useState<any>(null as any);
    const [anchorEls, setAnchorEls] = React.useState<any>({});

    //
    React.useEffect(() => {
        document.title = "Websites | DN";
    }, []);

    const reload = async (isActive: boolean = true ) => {
        setLoading(true);
        const q =  gql`    
            query q {
                websites {
                    id
                    code
                    tcpaId
                    url
                }
            }
        `;

        const response = await query(q, null);
        if (response && response.websites) {
            setAllWebsites(response.websites);
        }
        setLoading(false);

    }
    React.useEffect(() => {
        reload().then(x=>{});
    }, []);

    //
    return (
        <>
            <h2>Websites</h2>
            <FormGroup row={true}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Button aria-label="Add" onClick={() => { setWebsiteUpsert({}) }} variant={'contained'} color={'warning'}>
                    <AddBoxOutlinedIcon />&nbsp;Create Website
                </Button>
            </FormControl>
            </FormGroup>
            {loading ? <Loading/> :
                <>
                    {!allWebsites || allWebsites.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>
                                            Id
                                        </TableCell>
                                        <TableCell>
                                            Code
                                        </TableCell>
                                        <TableCell>
                                            Tcpa Id
                                        </TableCell>
                                        <TableCell>
                                            Url
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {allWebsites.map((row: any) => (
                                        <React.Fragment key={row.id}>
                                            <StripedTableRow>
                                            <TableCell>
                                                {row.id}
                                            </TableCell>
                                            <TableCell>
                                                {row.code}
                                            </TableCell>
                                            <TableCell>
                                                {row.tcpaId}
                                            </TableCell>
                                            <TableCell>
                                                {row.url}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                    }}
                                                >
                                                    <MoreVertIcon sx={{fontSize: '15px'}} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEls[row.id]}
                                                    open={!!anchorEls[row.id]}
                                                    onClose={() => {
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }}
                                                >
                                                    <MenuItem onClick={() => {
                                                        setWebsiteUpsert(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit Website</MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </StripedTableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>
                    }
                </>
            }
            <WebsiteUpsert website={websiteUpsert} setWebsite={setWebsiteUpsert} reload={() => {reload().then(() => {})}} />
        </>
    )
}
